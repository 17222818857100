/*eslint-disable */
const $ = window.jQuery = require('jquery');

import Push from 'push.js';
import audioPlay from '@/assets/audio/videocall_ringtone.mp3';
import {bus} from '@/main';
import commonSocket from "@/services/socket/";
import supportChatbot from '@/components/private/support-chatbot';
import videoCallNotification from '@/components/private/video-call-notification-popup/';
import videoSocketRef from "@/services/videoNotificationSocket/";

var audioPlayer;
export default {
  name: 'dashboard',
  components: {
    'support-chatbot' : supportChatbot,
    'video-call-alert' : videoCallNotification,
  },
  props: [],
  data () {
    return {
      selfUserId:'',
      incomingCallNotification:false,
      callerName:'',
      userIDforVideoCall:'',
      videoCallerUserId:''
    }
  },
  computed: {

  },
  mounted () {
    if(!localStorage.getItem('token')){
      this.$router.push('/');
    }
    if(localStorage.getItem('LoginedUserID')){
      this.selfUserId = localStorage.getItem('LoginedUserID')
    }
    this.$store.dispatch('getAuthHeader');
    bus.$emit('showHeaderbtn', true);
    setTimeout(() => {
      document.getElementById("preloader").style.display = "none";
    }, 200);
    this.commonSocketFun();
    this.videoNotficationAlertPopupForSocket();
    bus.$on('callerNotifiactionBus', (data) => {
      this.incomingCallNotification = data;
    });
    bus.$on('newChatMessage', (data) => {
      const payload = {
        type: "newChatMessage",
        message: "New Chat Message",
        fromUserID: data.fromUserID,
        toUserID: data.toUserID,
        second_user_id:data.toUserID,
        chatRoomId: data.chatRoomId,
        senderMsgData:data.senderMsgData,
        msgSenderFullName:data.msgSenderFullName,
        msgSenderFullName:data.msgSenderFullName,
        lbilWorkspace:'yes',
      };
      commonSocket.emit(payload);
    });
    bus.$on('newChatMessageOfGroupRoom', (data) => {
      const payload = {
        type: "newChatMessageOfGroupRoom",
        message: "New Chat Message Of Group Room",
        selfUserID: data.selfUserID,
        chatRoomId: data.chatRoomId,
        second_user_id:data.chatRoomId,
        senderMsgData:data.senderMsgData,
        msgSenderFullName:data.msgSenderFullName
      };
      commonSocket.emit(payload);
    });
  },
  methods: {
    playSound (sound) {
      if(sound) {
        audioPlayer = new Audio(sound);
        audioPlayer.play();
      }
    },
    stopSound () {
      if(audioPlayer == undefined) return; 
      audioPlayer.pause();
    },
    commonSocketFun(){
      let callerID = '3548d149becb44e9';
      commonSocket.connect(callerID);
      commonSocket.$on('connection', response => {
        const payload = {
          type: "userLogin",
          userID: this.selfUserId,
          message: "User Login"
        };
        commonSocket.emit(payload);
      });
      commonSocket.$on('userLogin', this.handleUserLogin);
      commonSocket.$on('userLogout', this.handleUserLogout);
      commonSocket.$on('patientRemark', this.handleRemarkData);
      commonSocket.$on('patientPrescription', this.handlePrescriptionData);
    },
    videoNotficationAlertPopupForSocket(){
      this.$store.dispatch('roomCreateIdforGlobalSocketMethod').then((res) => {
        let callerID = res.data.data.val;
        videoSocketRef.connect(callerID);
        videoSocketRef.$on('videoCallingNotification', this.getVideoNotificationMsg);
        videoSocketRef.$on('videoCallAlertPopupClosed', this.callerPopupClosedInOtherTabs);
        videoSocketRef.$on('newMessageNotification', this.handleNewMessageNotification);
        videoSocketRef.$on('newChatMessageOfGroupRoomNotification', this.handleNewMessageOfGroupRoomNotification);
        videoSocketRef.$on('messageIndicationForChatNotification', this.handlemessageIndicationForChatNotification);
        videoSocketRef.$on('TypingEventForSelectedUserNotification', this.handleTypingEventForSelectedUserNotification);
        videoSocketRef.$on('TypingEventForSelectedGroupNotification', this.handleTypingEventForSelectedGroupNotification);
        videoSocketRef.$on('messageDeleteForChatOfAllNotification', this.handleMessageDeleteForChatOfAllNotification);
        videoSocketRef.$on('messageForwardedOfUsersNotification', this.handleMessageForwardedOfUsersNotification);
      })
    },
    handlePrescriptionData(response) {
      const userID = response.patient_id;
      if(userID == this.selfUserId) return;
      try{
        const payload = {
          patient_id: userID,
        };
        bus.$emit('patientPrescriptionBus', payload);
      }catch(e) {
        console.log(e);
      }
    },
    handleRemarkData(response) {
      const userID = response.patient_id;
      if(userID == this.selfUserId) return;
      try{
        const payload = {
          patient_id: response.patient_id,
          title: response.title,
          data:response.data
        };
        bus.$emit('patientRemarkBus', payload);
      }catch(e) {
        console.log(e);
      }
    },
    handleUserLogout(response) {
      const userID = response.userID;

      if(userID == this.selfUserId) return;

      try {
        const payload = {
          userID: response.userID,
          is_online: false,
        };
        bus.$emit('userUpdateStatus', payload);
      } catch(e) {
        console.log(e);
      }
    },
    handleUserLogin(response) {
      const userID = response.userID;

      if(userID == this.selfUserId) return;

      try {
        const payload = {
          userID: response.userID,
          is_online: true,
        };
        bus.$emit('userUpdateStatus', payload);
      } catch(e) {
        console.log(e);
      }
    },
    getVideoNotificationMsg(getData){
      if(this.selfUserId == getData.targetId){
        if(Push.Permission.has()){
          Push.create('LBIL Video Call From ' + getData.displayName, {
            body:'Check Your LBIL to answer',
            timeout:5000,
            onClick: function () {
                window.focus();
                this.close();
            }
          })
        }
        $('body').addClass('videoNotificationPopup')
        this.callerName = getData.displayName
        this.videoCallerUserId = getData.callerID
        this.incomingCallNotification = true
        this.playSound(audioPlay)
      }
    },
    callerPopupClosedInOtherTabs(closeOtherTabData){
      if(this.selfUserId != closeOtherTabData.videoCallRoomId) return;
      if(closeOtherTabData.type == 'videoAlertPopupClose'){
        this.stopSound()
        $('body').removeClass('videoNotificationPopup')
        this.callerName = ''
        this.incomingCallNotification = false
      }
    },
    handleNewMessageNotification(response) {
      const toUserID = response.toUserID;
      const fromUserID = response.fromUserID;
      if(fromUserID == this.selfUserId || toUserID != this.selfUserId) return;
      try {
        const payload = {
          chatRoomId: response.chatRoomId,
          toUserID: response.toUserID,
          fromUserID: response.fromUserID,
          unreadCount: response.unreadCount,
          totalUnreadCount: response.totalUnreadCount,
          senderMsgData:response.senderMsgData,
          msgSenderFullName:response.msgSenderFullName
        };
        bus.$emit('updateMessageNotification', payload);
        bus.$emit('updateMessageNotifications', payload);
      } catch(e) {
        console.log(e);
      }
    },
    handleNewMessageOfGroupRoomNotification(response) {
      const toUserID = response.selfUserID;
      if(toUserID == this.selfUserId) return;
      for(var i = 0; i < response.ingroupusers.length; i++){
        if(response.ingroupusers[i] == this.selfUserId){
          try {
            const payload = {
              selfUserID: response.selfUserID,
              chatRoomId: response.chatRoomId,
              unreadCount: response.unreadCount,
              totalUnreadCount: response.totalUnreadCount,
              senderMsgData:response.senderMsgData,
              msgSenderFullName:response.msgSenderFullName
            };
            bus.$emit('updateMessageNotificationOfGroupRoom', payload);
            bus.$emit('updateMessageNotifications', payload);
          } catch(e) {
            console.log(e);
          }
        }
      }
    },
    handlemessageIndicationForChatNotification(response){
      const suserID = response.selfUserID;
      if(suserID == this.selfUserId) return;
      const userID = response.targetForChatUserId;
      if(userID == this.selfUserId){
        bus.$emit('msgIndicationStateBus', response);
      }else{
        if(response.ingroupusers){
          for(var i = 0; i < response.ingroupusers.length; i++){
            if(response.ingroupusers[i].user.id == this.selfUserId){
              bus.$emit('msgIndicationStateBus', response);
            }
          }
        }else{
          bus.$emit('msgIndicationStateBus', false);
        }
      }
    },
    handleTypingEventForSelectedUserNotification(response){
      const suserID = response.selfUserID;
      if(suserID == this.selfUserId) return;
      const userID = response.targetForChatUserId;
      if(userID == this.selfUserId){
        bus.$emit('TypingEventForSelectedUserBus', response);
      }
    },
    handleTypingEventForSelectedGroupNotification(response){
      const toUserID = response.selfUserID;
      if(toUserID == this.selfUserId) return;
      if(response.message == 'typing'){
        if(!response.ingroupuser) return;
        for(var i = 0; i < response.ingroupuser.length; i++){
          if(response.ingroupuser[i].user.id == this.selfUserId){
            bus.$emit('TypingEventForSelectedGroupBus', response);;
          }
          if(response.ingroupuser[i].user.id == toUserID){
            bus.$emit('getUserInfoTypingEventForSelectedGroupBus', response.ingroupuser[i].user);
          }
        }
      }else{
        bus.$emit('TypingEventForSelectedGroupBus', response);
      }
    },
    handleMessageDeleteForChatOfAllNotification(response){
      const toUserID = response.selfUserID;
      if(toUserID == this.selfUserId) return;
      bus.$emit('MessageDeleteForChatOfAllNotification', response);
    },
    handleMessageForwardedOfUsersNotification(response) {
      const toUserID = response.toUserID;
      if(toUserID == this.selfUserId) return;
      try {
        const payload = {
          chat_data: response.chat_data,
          toUserID: response.toUserID,
          targetUsersID:response.targetUsersID,
          colour:response.colour,
          message_id_arr:response.message_id_arr,
        };
        bus.$emit('forwardedMessageNotifications', payload);
      } catch(e) {
        console.log(e);
      }
    },
    closeSocket() {
      if(commonSocket.isOpen()) {
        commonSocket.close();
      }
      if(videoSocketRef.isOpen()) {
        videoSocketRef.close();
      }
    },
    closeSocket() {
      if(commonSocket.isOpen()) {
        commonSocket.close();
      }
      if(videoSocketRef.isOpen()) {
        videoSocketRef.close();
      }
    },
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.closeSocket)
  },
  beforeDestroy() {
    if(commonSocket.isOpen()) {
      commonSocket.close();
    }
    if(videoSocketRef.isOpen()) {
      videoSocketRef.close();
    }
    window.removeEventListener("beforeunload", this.closeSocket);
  },
  beforeCreate: function() {
    $('body').addClass('dashboard')
  },
  beforeRouteLeave: function (to, from, next) {
    $('body').removeClass('dashboard')
    next();
  },
}